<template>
    <div class="loginContent">
        <div class="loginBox">
            <div class="logoTitle">
                <img src="@/assets/htui/logo.png" class="logoImg">
                <img src="@/assets/htui/close.png" class="closeing" @click="closeMask">
            </div>
            <div class="maintitle">{{ type == 3 ? '账号密码找回' : (type == 1 ? '手机号/账号登录' : '手机号/账号注册') }}</div>
            <!-- 用户登录 手机密码 -->
            <div class="neic" v-if="type == 1">
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/phoneIcon.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="text" maxlength="11" placeholder="请输入手机号/账号"
                            v-model="loginForm.username" />
                    </div>
                </div>
                <div class="emptyContent"></div>
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/yanzheng.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="password" placeholder="请输入密码" v-model="loginForm.password" />
                    </div>
                </div>

                <div class="czContent">
                    <div @click="setRegister">{{ type == 1 ? '没有账号去注册' : '已有账号去登录' }}</div>
                    <div>|</div>
                    <div @click="forgetTap">忘记密码?</div>
                </div>
                <div class="startButton" @click="loginIn">{{ type == 1 ? '登录' : (type == 2 ? '注册' : '确定重置') }}</div>
                <div class='tokenDay'>
                    <input type="checkbox" v-model="checked"> <span @click="checked = !checked">10天免登录</span>
                </div>
                <div class="bottomTip">未注册用户验证后自动登录，登录即代表同意 <span @click="goAgreement">《用户服务协议》</span> </div>
            </div>

            <!-- 用户注册 手机号 密码 -->
            <div class="neic" v-if="type == 2">
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/phoneIcon.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="text" maxlength="11" placeholder="请输入手机号"
                            v-model="registerForm.username" />
                    </div>
                </div>
                <div class="emptyContent"></div>
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/yanzheng.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="password" placeholder="请输入密码"
                            v-model="registerForm.password" />
                    </div>
                </div>
                <div class="emptyContent"></div>
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/yanzheng.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="password" placeholder="请再次确认密码"
                            v-model="registerForm.confirmPassword" />
                    </div>
                </div>

                <!-- <div class="emptyContent"></div>
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/inviate.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="text" placeholder="邀请码" v-model="inviteCode" />
                    </div>
                </div> -->
                <div class="emptyContent"></div>
                <div class="checkContent">
                    <div class="checkBox">
                        <div>
                            <img src="@/assets/htui/yanzheng.png">
                        </div>
                        <div>
                            <el-input class="inputClass" type="text" placeholder="请输入手机验证码"
                                v-model="registerForm.verificationCode" />
                        </div>
                    </div>
                    <div class='checkNum' v-if="!registerLoading" @click="sendPhoneMessage(1)">获取验证码</div>
                    <div class='checkNumDisable' v-else @click="retrySend()">重新发送 ({{ countdown }})</div>
                </div>


                <div class="czContent">
                    <div @click="setRegister">{{ type == 1 ? '没有账号去注册' : '已有账号去登录' }}</div>
                    <div>|</div>
                    <div @click="forgetTap">忘记密码?</div>
                </div>
                <div class="startButton" @click="loginIn">{{ type == 1 ? '登录' : (type == 2 ? '注册' : '确定重置') }}</div>
                <div class='tokenDay'>
                    <input type="checkbox" v-model="checked"> <span @click="checked = !checked">10天免登录</span>
                </div>
                <div class="bottomTip">未注册用户验证后自动登录，登录即代表同意 <span @click="goAgreement">《用户服务协议》</span> </div>
            </div>


            <!-- 用户找回密码  手机号 密码 二次确认密码--->
            <div class="neic" v-if="type == 3">
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/phoneIcon.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="text" maxlength="11" placeholder="请输入手机号"
                            v-model="findPasswordForm.phonenumber" />
                    </div>
                </div>
                <div class="emptyContent"></div>
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/yanzheng.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="password" placeholder="请输入新密码"
                            v-model="findPasswordForm.password" />
                    </div>
                </div>
                <div class="emptyContent"></div>
                <div class="phoneBox">
                    <div>
                        <img src="@/assets/htui/yanzheng.png">
                    </div>
                    <div>
                        <el-input class="inputClass" type="password" placeholder="请再次确认新密码"
                            v-model="findPasswordForm.confirmPassword" />
                    </div>
                </div>
                <div class="emptyContent"></div>
                <div class="checkContent">
                    <div class="checkBox">
                        <div>
                            <img src="@/assets/htui/yanzheng.png">
                        </div>
                        <div>
                            <el-input class="inputClass" type="text" placeholder="请输入手机验证码"
                                v-model="findPasswordForm.verificationCode" />
                        </div>
                    </div>
                    <div class='checkNum' v-if="!registerLoading" @click="sendPhoneMessage(2)">获取验证码</div>
                    <div class='checkNumDisable' v-else @click="retrySend()">重新发送 ({{ countdown }})</div>
                </div>


                <div class="czContent">
                    <div @click="setRegister">{{ type == 1 ? '没有账号去注册' : '已有账号去登录' }}</div>
                    <div>|</div>
                    <div @click="forgetTap">忘记密码?</div>
                </div>
                <div class="startButton" @click="loginIn">{{ type == 1 ? '登录' : (type == 2 ? '注册' : '确定重置') }}</div>
                <div class='tokenDay'>
                    <input type="checkbox" v-model="checked"> <span @click="checked = !checked">10天免登录</span>
                </div>
                <div class="bottomTip">未注册用户验证后自动登录，登录即代表同意 <span @click="goAgreement">《用户服务协议》</span> </div>
            </div>

        </div>
    </div>
</template>

<script>
import { setToken, TokenKey } from '@/utils/auth'
import Cookies from "js-cookie";
import { login, register, sendsms, forgetPassword } from '@/api/login';
export default {
    data() {
        return {
            type: 1, //1登录 2注册 3找回
            checked: true,
            loginForm: {
                username: '',
                password: '',
            },
            registerForm: {
                username: null,
                nickName: null,
                password: null,
                phonenumber: null,
                confirmPassword: null,
                verificationCode: null
            },
            //找回密码params
            findPasswordForm: {
                phonenumber: null,
                password: null,
                confirmPassword: null,
                verificationCode: null
            },
            uuid: '',
            //发送短信等待90s
            registerLoading: false,
            //90s倒计时
            countdown: 90,
            //setintervier 倒计时
            sendCountdown: null,
            inviteCode: '',
            //用户来源
            referrer: ''

        };
    },
    beforeDestroy() {
        //清除定时器
        clearInterval(this.sendCountdown)
    },
    created() {
        this.inviteCode = localStorage.getItem('inviteCode') ? localStorage.getItem('inviteCode') : ''
        this.referrer = localStorage.getItem('referrer') ? localStorage.getItem('referrer') : ''
    },
    methods: {
        //关闭登录注册弹框
        closeMask() {
            this.$parent.showLoginPopup = false;
        },
        //点击登录、注册、重置
        loginIn() {
            if (this.type == 1) {
                //登录部分
                if (!this.loginForm.username || !this.loginForm.password) {
                    this.$message.warning('请完善用户名和密码信息')
                    return
                }
                this.commonLogin(this.loginForm)
            } else if (this.type == 2) {
                //注册部分
                if (!this.registerForm.username || !this.registerForm.password || !this.registerForm.verificationCode || !this.registerForm.confirmPassword) {
                    this.$message.warning('请完善注册信息')
                    return
                }
                //手机号验证
                if (!this.validatePhone(this.registerForm.username)) {
                    this.$message.warning('请输入正确的手机号')
                    return
                }
                //密码不能低于6位
                if (this.registerForm.password.length < 6) {
                    this.$message.warning('密码不能低于6位')
                    return
                }
                //二次确认密码
                if (this.registerForm.password != this.registerForm.confirmPassword) {
                    this.$message.warning('两次密码不一致')
                    return
                }
                //注册接口
                register({
                    username: this.registerForm.username,
                    nickName: this.registerForm.username,
                    phonenumber: this.registerForm.username,
                    password: this.registerForm.password,
                    verificationCode: this.registerForm.verificationCode,
                    uuid: this.uuid,
                    upInviteCode: this.inviteCode,
                    referer: this.referrer
                }).then(res => {
                    this.$message.success(res.msg)
                    this.commonLogin(this.registerForm)
                })
            } else if (this.type == 3) {
                //重置密码
                if (!this.findPasswordForm.phonenumber || !this.findPasswordForm.password || !this.findPasswordForm.verificationCode || !this.findPasswordForm.confirmPassword) {
                    this.$message.warning('请完善重置信息')
                    return
                }
                //手机号验证
                if (!this.validatePhone(this.findPasswordForm.phonenumber)) {
                    this.$message.warning('请输入正确的手机号')
                    return
                }
                //密码不能低于6位
                if (this.findPasswordForm.password.length < 6) {
                    this.$message.warning('密码不能低于6位')
                    return
                }
                //二次确认密码
                if (this.findPasswordForm.password != this.findPasswordForm.confirmPassword) {
                    this.$message.warning('两次密码不一致')
                    return
                }
                //手机号验证
                if (!this.validatePhone(this.findPasswordForm.phonenumber)) {
                    this.$message.warning('请输入正确的手机号')
                    return
                }
                forgetPassword(
                    {
                        username: this.findPasswordForm.phonenumber,
                        phonenumber: this.findPasswordForm.phonenumber,
                        password: this.findPasswordForm.password,
                        verificationCode: this.findPasswordForm.verificationCode,
                        uuid: this.uuid
                    }
                ).then(() => {
                    this.setRegister();
                    this.$message.warning('密码重置成功,请重新登录');
                })
            }
        },
        //登录接口
        commonLogin(commonParams) {
            login(commonParams).then(res => {
                var date = new Date();
                date.setTime(date.getTime() + (10 * 24 * 60 * 60 * 1000));
                if (this.checked) {
                    Cookies.set(TokenKey, res.msg, { expires: date })
                    localStorage.setItem('AccessToken_expriesTime', date)
                } else {
                    localStorage.removeItem('AccessToken_expriesTime')
                    setToken(res.msg);
                }
                this.$parent.isLogin = true;
                this.$parent.showLoginPopup = false;
                this.$bus.$emit('loginButton', true);
            })
        },
        //重置数据信息
        resetData() {
            this.registerForm.username = null;
            this.registerForm.password = null;
            this.registerForm.verificationCode = null;

            this.loginForm.username = null;
            this.loginForm.password = null;

            this.findPasswordForm.phonenumber = null;
            this.findPasswordForm.password = null;
            this.findPasswordForm.verificationCode = null;
            this.uuid = ''

            this.checked = true;
        },
        //忘记密码
        forgetTap() {
            this.resetData();
            this.type = 3;
        },
        //点击账号注册/登录 切换
        setRegister() {
            this.resetData();
            this.type == 3 ? this.type = 1 : (this.type == 1 ? this.type = 2 : this.type = 1)
        },
        //跳转用户协议
        goAgreement() {
            this.closeMask()
            this.$router.push('/agreement')
        },
        //发送短信验证码
        sendPhoneMessage(type) {
            clearInterval(this.sendCountdown)

            //type==1 注册 2 找回
            let phone = type == 1 ? this.registerForm.username : this.findPasswordForm.phonenumber
            //手机号不为空
            if (!phone) {
                this.$message.warning('请填写手机号')
                return
            }
            //手机号验证正确
            if (!this.validatePhone(phone)) {
                this.$message.warning('请输入正确的手机号')
                return
            }
            //发送短信
            sendsms({ phone, type }).then((res) => {
                this.uuid = res.uuid;
                this.registerLoading = true
                //开始倒计时 === > 不可点击
                this.sendCountdown = setInterval(() => {
                    this.countdown -= 1;
                    if (this.countdown < 1) {
                        clearInterval(this.sendCountdown)
                        this.registerLoading = false;
                        this.sendCountdown = null
                        this.countdown = 90
                    }
                }, 1000);
            })
        },
        //重试发送验证码
        retrySend() {
            this.$message.warning(`请${this.countdown}秒后重试`)
        },
        //手机号正则验证
        validatePhone(phoneNumber) {
            const regExp = /^1[3456789]\d{9}$/;
            if (regExp.test(phoneNumber)) {
                return true;
            } else {
                return false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.loginContent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 600px;

    .emptyContent {
        height: 20px;
    }

    .loginBox {
        min-width: 480px;
        // min-height: 500px;
        padding-bottom: 25px;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;

        .logoTitle {
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(245, 245, 245);
            position: relative;

            .logoImg {
                width: 170px;
                height: 45px;

            }

            .closeing {
                width: 25px;
                height: 25px;
                position: absolute;
                right: 20px;
                top: 50%;
                margin-top: -10px;
                cursor: pointer;
            }
        }

        .maintitle {
            font-size: 18px;
            font-weight: bold;
            color: black;
            text-align: center;
            margin: 20px auto;
        }

        .neic {
            padding: 10px 40px 0 40px;
        }

        .phoneBox {
            border: 1px solid #e6e6e6;
            display: flex;
            box-sizing: border-box;
            // padding: 5px 0;
            border-radius: 4px;
            overflow: hidden;
            align-items: center;

            ::v-deep .el-input__inner {
                border: none !important;
                padding-left: 0 !important;
            }

            >div:nth-child(1) {
                width: 46px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 20px;
                    height: 20px
                }
            }

            >div:nth-child(2) {
                width: calc(100% - 49px);
                display: flex;
                align-items: center;
                margin-left: 5px;

                input {
                    display: block;
                    width: calc(100% - 10px);
                    font-size: 14px;
                    line-height: 28px;
                    color: #262626;
                    background-color: inherit;
                    border-style: none;
                }
            }
        }

        .checkContent {
            display: flex;
            align-items: center;

            ::v-deep .el-input__inner {
                border: none !important;
                padding-left: 0 !important;
            }

            .checkBox {
                border: 1px solid #e6e6e6;
                display: flex;
                box-sizing: border-box;
                // height: 46px;
                overflow: hidden;
                width: calc(100% - 140px);
                border-radius: 4px;

                >div:nth-child(1) {
                    width: 46px;
                    // height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 20px;
                        height: 20px
                    }
                }


                >div:nth-child(2) {
                    width: calc(100% - 49px);
                    display: flex;
                    align-items: center;
                    margin-left: 5px;

                    input {
                        display: block;
                        width: calc(100% - 10px);
                        font-size: 14px;
                        line-height: 28px;
                        color: #262626;
                        background-color: inherit;
                        border-style: none;
                    }
                }
            }

            .checkNum {
                min-width: 110px;
                margin-left: 30px;
                // height: 44px;
                padding: 0px 4px;
                white-space: nowrap;
                line-height: 44px;
                display: block;
                font-size: 14px;
                color: #fff;
                background: rgb(45, 141, 240);
                border-radius: 8px;
                cursor: pointer;
            }

            .checkNumDisable {
                min-width: 110px;
                margin-left: 30px;
                // height: 44px;
                padding: 0px 4px;
                white-space: nowrap;
                line-height: 44px;
                display: block;
                font-size: 14px;
                color: #333333;
                border: 1px solid #e6e6e6;
                background: white;
                border-radius: 8px;
                cursor: pointer;
            }

            .tuimg {
                width: 110px;
                margin-left: 30px;
                height: 44px;
                line-height: 44px;
                display: block;
                font-size: 14px;
                color: #fff;
                border: 1px solid rgb(45, 141, 240);

            }
        }

        .tokenDay {
            display: flex;
            justify-content: left;
            color: rgb(45, 141, 240);
            font-size: 14px;
            margin-top: 10px;

            span {
                cursor: pointer;
            }
        }

        .czContent {
            height: 30px;
            font-size: 12px;
            color: #2d8df0;
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: center;
            padding: 15px 0;

            >div {
                cursor: pointer;
            }

            >div:nth-child(2) {
                margin: 0 10px;
            }
        }

        .startButton {
            overflow: hidden;
            width: 100%;
            padding: 5px 0;
            line-height: 40px;
            border: 0 none;
            background-color: #2d8df0;
            color: #ffffff;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
        }

        .bottomTip {
            margin-top: 15px;
            line-height: 20px;
            color: #99999c;
            text-align: left;
            font-size: 12px;

            span {
                color: #2d8df0;
                cursor: pointer;

            }
        }
    }
}

::v-deep .el-input.is-disabled .el-input__inner {
    background-color: white !important;
}
</style>