import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui';
import { getToken, removeToken } from '@/utils/auth';
import errorCode from '@/utils/errorCode';
import cache from '@/plugins/cache';
import { tansParams } from '@/utils/base'

const Authorization = 'Bearer app-oU8NAOQ8UuD0bv3vA571BI9e'

// 创建axios实例

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const service = axios.create({
    // 超时
    timeout: 60 * 1000
});

//检查Token是否已到期
function isCookieExpired() {
    const expires = localStorage.getItem('AccessToken_expriesTime')
    const currentDate = new Date();
    const expirationDate = new Date(expires);
    return currentDate.getTime() > expirationDate.getTime();
}

// 请求拦截器
service.interceptors.request.use((config) => {
    // 是否需要设置 token
    config.headers['Authorization'] = Authorization
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;

    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
        if (config.type) {
            config.headers['Content-Type'] = config.type;
        }
        // const requestObj = {
        //     url: config.url,
        //     data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        //     time: new Date().getTime()
        // };
        // const sessionObj = cache.session.getJSON('sessionObj');
        // if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        //     cache.session.setJSON('sessionObj', requestObj);
        // } else {
        //     const s_url = sessionObj.url;
        //     const s_data = sessionObj.data;
        //     const s_time = sessionObj.time;
        //     const interval = 1000;
        //     if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        //         const message = '数据正在处理，请勿重复提交';
        //         console.warn(`[${s_url}]: ` + message);
        //         return Promise.reject(new Error(message));
        //     } else {
        //         cache.session.setJSON('sessionObj', requestObj);
        //     }
        // }
    }
    return config;
}, (error) => {
    Promise.reject(error)
})

//响应拦截器
service.interceptors.response.use((res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    const msg = errorCode[code] || res.data.msg || errorCode['default'];
    // 二进制数据则直接返回
    if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
        return res.data;
    }
    if (code == 401 || code == 302) {
        MessageBox.confirm('登录状态已过期，请重新登录', '系统提示', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
        }
        ).then(() => {
            removeToken()
            location.href = '/index';
        })
        return Promise.reject('会话已过期，请重新登录。');
    } else if (code == 500) {
        Message({
            message: msg,
            type: 'warning'
        });
        return Promise.reject(new Error(msg));
    } else if (code != 200) {
        Notification.error({
            title: msg
        });
        return Promise.reject('error');
    } else {
        return res.data;
    }
}, (error) => {
    console.log(error, "error")
    if (error != '登录状态已过期，请重新登录') {
        Message({
            message: error,
            type: 'warning',
            duration: 5 * 1000
        });
    }
    return Promise.reject(error);
})

export default service;
