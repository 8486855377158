<template>
    <div class="cashbackPopup">
        <div class="popupContent">
            <div class='closeImg' @click="closePopup">
                <img src="@/assets/htui/close.png" alt="">
            </div>

            <div class="leftContent">
                <!-- <div class="basemessage">
                    <div>
                        <img src="@/assets/htui/usericon.png" alt="">
                    </div>
                    <div>
                        <div>账号：15076305780</div>
                        <div>基础版</div>
                    </div>
                </div> -->
                <div class="xiushi" style="font-style: italic;">开通会员 畅享高级权益 !
                </div>

                <div class="viptitle">
                    律审灵犀
                </div>
                <div class="descauth">精准审核,智能识别合同风险</div>

                <!-- <div class="xiushi">畅享高级权益</div> -->
                <div>
                    <div
                        style="font-size: 14px;display: flex;align-items: center;color: #8b451e;margin: 30px 0 4px 0;font-weight: 700;">
                        <img src="@/assets/htui/vipuser.gif" alt="" style="width: 22px;height: 22px;margin-right: 4px;">
                        会员高级权益
                    </div>
                    <div class="qyli">• 合同审核 <span>无限次</span> /天</div>
                    <div class="qyli">• 合同模版下载 <span>无限次</span> /天</div>
                    <div class="qyli">• AI法律助手问答 <span>无限次</span> /天</div>
                    <div class="qyli">• 合同比对 <span>无限次</span> /天</div>
                    <div class="qyli">• Web/H5/微信小程序多端会员权益共享</div>
                    <div class="qyli">• 专属客服支持</div>
                </div>
            </div>

            <div class="rightContent">
                <div class="choiceContent">
                    <div class="basemessage">
                        <div>
                            <img src="@/assets/htui/usericon.png" alt="">
                        </div>
                        <div>
                            <div>账号：15076305780</div>
                            <div>
                                <div>月度会员</div>
                                <span style="color: gray;margin-left: 10px">您的会员将于2024/10/18 23:59到期</span>
                            </div>
                        </div>
                    </div>

                    <div
                        style="display: flex;text-align: left;color: #333;font-size: 16px;font-weight: 600;margin: 20px 0;">
                        会员购买/续费
                    </div>
                    <div class="priceContent">
                        <div :class="activeIndex == index ? 'priceLi activePrice' : 'priceLi'"
                            v-for="(item, index) in  priceList " @click="tapPrice(index)">
                            <div>{{ item.name }}</div>
                            <div> <span style="font-size: 18px;">￥</span>{{ item.price }}</div>
                            <div>原价￥{{ item.orPrice }}</div>
                            <div>平均￥{{ item.pjPrice }}/天,享高级权益</div>
                        </div>
                    </div>

                    <div class="xufeism">
                        <div>开通会员后全平台可享受高级权益，月内有效</div>
                        <div>到期将按每月￥30自动续费，可随时取消</div>
                    </div>
                </div>
                <div class="payContent">
                    <div class="inner_pay">
                        <div class="leftcode">
                            <img src="@/assets/htui/codeImg.jpg" alt="">
                        </div>
                        <div class="rightdescprition">
                            <div> <span>实付：</span> <span>￥</span><span>29.9</span></div>
                            <div>开通后有效期至： <span>2025-10-13</span></div>
                            <div>
                                <img src="@/assets/htui/wxpay.png" alt="">
                                微信扫码支付
                            </div>

                            <div>购买即表示接受 <span>《充值服务协议》</span> </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getUserInfo, getConfigKey } from '@/api/index'
import Clipboard from 'clipboard';
import { getToken } from '@/utils/auth';
export default {
    data() {
        return {
            listData: [
                {
                    name: '',
                    avatar: ''
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h1.jpg'
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h2.jpg'
                },
                {
                    name: '',
                    avatar: ''
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h3.jpg'
                },
                {
                    name: '',
                    avatar: ''
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h5.jpg'
                },
                {
                    name: '',
                    avatar: '/static/img/avatarFic/h4.jpg'
                },
                {
                    name: '',
                    avatar: ''
                },
                {
                    name: '',
                    avatar: ''
                },
            ],
            classOption: {
                limitMoveNum: 2,
                direction: 3,
                step: 0.3,
                hoverStop: false
            },
            baseUrl: '',
            InviteCodeUrl: '',
            selfInviteCode: '',
            isLogin: false,
            firstLevelRatio: '',
            secondLevelRatio: '',
            activeIndex: 0,
            priceList: [
                {
                    name: '连续包月',
                    price: '29.9',
                    orPrice: '60',
                    pjPrice: '0.4'

                },
                {
                    name: '连续包季',
                    price: '69.9',
                    orPrice: '240',
                    pjPrice: '0.35'

                },
                {
                    name: '连续包年',
                    price: '299.9',
                    orPrice: '480',
                    pjPrice: '0.21'

                }
            ]
        };
    },
    components: {
        vueSeamlessScroll
    },

    mounted() {
        this.getConf();
        this.baseUrl = window.location.protocol + '//' + window.location.hostname + '/';
        if (getToken()) {
            this.isLogin = true;
            this.getUserData()
        } else {
            this.isLogin = false;
            this.InviteCodeUrl = this.baseUrl + 'index'
        }
    },

    methods: {
        //获取个人信息
        getUserData() {
            getUserInfo().then((res) => {
                this.selfInviteCode = res.data.selfInviteCode;
                this.InviteCodeUrl = `${this.baseUrl}index?inviteCode=${this.selfInviteCode}`
            })
        },

        tapPrice(index) {
            this.activeIndex = index;
        },
        //复制
        copyCode() {
            let clipboard = new Clipboard('.copy')
            clipboard.on('success', e => {
                this.$message({
                    message: "复制成功",
                    type: "success",
                    customClass: 'messageIndex'
                })
                // 释放内存
                clipboard.destroy()
            })
        },
        //关闭弹框
        closePopup() {
            this.$parent.showVipRechargePopup = false;
        },
        //立即登录
        goLogin() {
            this.$parent.showCashbackPopup = false;
            this.$parent.showLoginPopup = true;
        },
        //获取返现比例
        getConf() {
            getConfigKey('first_level_ratio').then((res) => {
                this.firstLevelRatio = Number(res.msg)
            })
            getConfigKey('second_level_ratio').then((res) => {
                this.secondLevelRatio = Number(res.msg)
            })
        },
    },
};
</script>


<style lang="scss" scoped>
.cashbackPopup {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999999 !important;

    @keyframes scaleShow {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    .popupContent {
        background: white;
        position: relative;
        width: 950px;
        height: 600px;
        box-sizing: border-box;
        animation: scaleShow 0.6s ease forwards;
        display: flex;
        padding: 0 !important;
        border-radius: 12px;
        overflow: hidden;

        .closeImg {
            position: absolute;
            right: 20px;
            top: 10px;
            cursor: pointer;

            img {
                width: 30px;
                height: 30px;
            }
        }

        .leftContent {
            height: 100%;
            width: 300px;
            background-image: url(../../../assets/htui/vipcharge.png), linear-gradient(170deg, #fff3d6, #ffdb95);
            background-size: 200px auto, cover;
            background-position: top, 50%;
            background-repeat: no-repeat, no-repeat;
            padding: 24px 0 24px 16px;
            box-sizing: border-box;



            .viptitle {
                font-size: 20px;
                font-weight: 600;
                text-align: left;
                margin-top: 10px;
                color: #8b451e;
            }

            .descauth {
                text-align: left;
                font-size: 14px;
                font-weight: 500;
                color: #8b451e;
                margin-top: 4px;
            }

            .xiushi {
                font-size: 20px;
                font-weight: 700;
                margin: 0px 0 20px 0;
                display: flex;
                color: #ff4f00;
            }

            .qyli {
                font-size: 14px;
                display: flex;
                margin-bottom: 30px;
                padding-left: 5px;
                color: #8b451e;
                margin-top: 15px;
                font-weight: 500;

                span {
                    color: #ff4f00;
                    margin-left: 5px;
                    // font-weight: 700;

                }
            }


        }

        .rightContent {
            width: calc(100% - 300px);
            height: 100%;
            // background-color: red;

            .choiceContent {
                height: calc(100% - 210px);
                width: 100%;
                padding: 15px 25px;
                box-sizing: border-box;

                .basemessage {
                    height: 60px;
                    display: flex;
                    align-items: center;
                    color: #8b451e;
                    margin: 5px 0;

                    >div:nth-child(1) {
                        width: 50px;
                        height: 50px;

                        img {
                            width: 100%;
                            height: 100%;
                        }

                    }

                    >div:nth-child(2) {
                        margin-left: 8px;

                        >div:nth-child(1) {
                            font-size: 18px;
                            margin-bottom: 2px;
                            display: flex;
                            color: #000000;
                            font-weight: bold;
                        }

                        >div:nth-child(2) {
                            display: flex;

                            >div:nth-child(1) {
                                height: 25px;
                                width: 70px;
                                background: linear-gradient(270deg, #6f3c00, #a56213);
                                color: white;
                                font-size: 14px;
                                border-radius: 50px;
                                display: flex;
                                text-align: center;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            >span {
                                color: gray;
                                font-size: 14px;
                                display: flex;
                                align-items: center;
                            }

                        }
                    }
                }

                .priceContent {
                    height: 190px;
                    width: 100%;
                    margin-top: 10px;
                    display: flex;

                    .priceLi {
                        height: 100%;
                        width: 200px;
                        border: 1px solid #e3e3e3;
                        border-radius: 15px;
                        margin-right: 20px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        position: relative;
                        overflow: hidden;

                        >div:nth-child(1) {
                            color: #000000;
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 26px;
                            text-align: center;
                        }

                        >div:nth-child(2) {
                            color: #000000;
                            font-size: 28px;
                            font-weight: 600;
                            line-height: 40px;
                        }

                        >div:nth-child(3) {
                            color: gray;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 20px;
                            text-decoration: line-through;
                        }

                        >div:nth-child(4) {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            font-size: 13px;
                            background-color: #f5f5f5;
                            height: 28px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: gray;
                        }
                    }

                    .priceLi:hover {
                        border: 1px solid #ff4f00;
                        background-size: 100% 100%;
                    }

                    .activePrice {
                        border: 1px solid #ff4f00;
                        background-image: url(../../../assets/htui/paylight.gif);
                        background-size: 100% 100%;

                        >div:nth-child(4) {
                            background-color: #ffede0;
                            color: #c27142;
                        }
                    }

                    >div:nth-last-child(1) {
                        margin-right: 0
                    }
                }

                .xufeism {
                    display: flex;
                    flex-direction: column;
                    font-size: 14px;
                    color: #b2b2b2;
                    text-align: left;
                    margin: 10px 0;

                    >div {
                        margin: 1px 0;
                    }
                }
            }

            .payContent {
                height: 210px;
                width: 100%;
                display: flex;
                padding: 15px 25px;
                box-sizing: border-box;

                .inner_pay {
                    width: 100%;
                    height: 100%;
                    display: flex;

                    .leftcode {
                        width: 160px;
                        height: 160px;
                        border: 1px solid #e3e3e3;
                        padding: 12px;
                        box-sizing: border-box;
                        margin-right: 14px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .rightdescprition {
                        padding-top: 15px;

                        >div:nth-child(1) {
                            display: flex;
                            text-align: left;
                            font-size: 16px;
                            align-items: flex-end;

                            >span:nth-child(1) {
                                font-size: 16px;
                            }

                            >span:nth-child(2) {
                                font-size: 16px;
                                color: #ff4d4f;
                            }

                            >span:nth-child(3) {
                                font-size: 30px;
                                color: #ff4d4f;
                                font-weight: 500;
                            }
                        }

                        >div:nth-child(2) {
                            margin-top: 15px;
                            font-size: 12px;
                            display: flex;
                            text-align: left;
                            align-items: flex-end;

                            >span {
                                color: #007bff
                            }
                        }

                        >div:nth-child(3) {
                            display: flex;
                            text-align: left;
                            font-size: 12px;
                            margin-top: 15px;

                            img {
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;
                            }
                        }

                        >div:nth-child(4) {
                            display: flex;
                            text-align: left;
                            font-size: 12px;
                            margin-top: 15px;

                            >span {
                                color: #007bff;
                            }
                        }
                    }

                    // background-color: #f5f5f5;
                }

            }
        }
    }
}
</style>