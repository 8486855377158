<template>
    <div>
        <!-- 头部导航  已更新样式-->
        <div :class="activeIndex == '/index' ? 'top_section' : 'top_section whiteContent'">
            <div class="visible_area">
                <div class="logoAndtitle">
                    <div class="logo">
                        <img src="@/assets/logo.png" alt="">
                        合同智能审核
                    </div>
                    <div class="routers">
                        <div v-for="(item, index) in routerList" :key="index">
                            <span :class="activeIndex == item.linkUrl ? 'activeColor' : 'nomalColor'"
                                @click="goRouter(item)">{{ item.routerName }}</span>
                        </div>
                    </div>
                </div>
                <div class="wxLogin" @click="showLoginPopup = true" v-if="!isLogin">
                    <img src="@/assets/htui/index/userPic.png" alt="">
                    登录/注册
                </div>
                <div v-else class="wxloginuser">
                    <span @click='tapUser'>个人中心</span>
                    <span>|</span>
                    <span @click='tapLoginOut'>退出登录</span>
                    <div class="vipContent" v-if="isupline">
                        <div class="floatAni"></div>
                        <img src="@/assets/htui/vipuser.gif" alt="">
                        <span>{{ isVip ? '我的会员' : '29.9开通会员' }}</span>

                        <div class="floatBox">
                            <div class="floatinner" v-if="!isVip">
                                <div class="vipTitle">
                                    <img src="@/assets/htui/vipicon.png" alt="">
                                    律审灵犀包月会员
                                </div>
                                <div class="section1">高级功能</div>
                                <div class="description1">智能审查不限次，AI助手咨询不限次，智能比对不限次</div>
                                <div class="section1">海量范本</div>
                                <div class="description1">各类优质合同范本免费下载</div>
                                <div class="section1">多端通用</div>
                                <div class="description1">Web、手机端H5、微信小程序会员权益</div>
                                <div class="btnStart" @click="showVipRecharge">立即开通</div>
                            </div>
                            <div v-else class="floatinner">
                                <div class="box-top">
                                    <div>
                                        <img src="@/assets/htui/usericon.png" alt="">
                                    </div>
                                    <div>
                                        <div>15088888888</div>
                                        <div>当前包月会员</div>
                                    </div>
                                </div>
                                <div class="vipdata">
                                    <div>连续包月</div>
                                    <div>有效期至:2024-12-10</div>
                                    <div @click="showVipRecharge">立即续费</div>
                                </div>

                                <div style="display:flex;align-items: center;justify-content: center;margin: 10px 0;">
                                    <div class="line-l"></div>
                                    <div class="linetext">正在尊享会员权益</div>
                                    <div class="line-r"></div>
                                </div>

                                <div class="funList">
                                    <div>
                                        <div>合同审核</div>
                                        <div>剩余用量：无限次</div>
                                    </div>
                                    <div>
                                        <div>AI助手问答</div>
                                        <div>剩余用量：无限次</div>
                                    </div>
                                    <div>
                                        <div>优质范本</div>
                                        <div>剩余用量：无限次</div>
                                    </div>
                                    <div>
                                        <div>合同比对</div>
                                        <div>剩余用量：无限次</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="right_user" v-if="isupline">
                        <img src="@/assets/htui/userimg.png" alt="" @click='tapUser'>
                    </div>

                </div>
            </div>
        </div>

        <div class="emptyContent" v-if="activeIndex != '/index'"></div>

        <div>
            <router-view></router-view>
        </div>

        <div class=" footerContent" v-if="activeIndex != '/contractPreview'">
            <!-- <div class="wztitle">合同智能审核</div> -->
            <div class="menuList">
                <div>
                    <span>功能中心</span>
                    <ul>
                        <li v-for="( item, index ) in  routerList.slice(0, 4) " :key="index" @click="goRouter(item)">
                            {{ item.routerName }}
                        </li>
                    </ul>
                    <ul>
                        <li v-for="( item, index ) in  routerList.slice(4, 7) " :key="index" @click="goRouter(item)">
                            {{ item.routerName }}
                        </li>
                    </ul>
                </div>

                <div class="compMessage">
                    <span>联系我们</span>
                    <div>联系电话：13633113672</div>
                    <div>公司地址：河北省石家庄市桥西区裕华西路616号1-3-201</div>
                </div>
            </div>

            <div class="beianhao">Copyright © 2024 <a href="https://beian.miit.gov.cn/">{{
            recordNumber }}</a>
            </div>

        </div>
        <loginPopup v-if="showLoginPopup"></loginPopup>
        <!-- 返现弹框 -->
        <cashbackTank v-if="showCashbackPopup"> </cashbackTank>
        <!-- 充值VIP弹框 -->
        <vipRecharge v-if="showVipRechargePopup"></vipRecharge>

        <!-- 悬浮 -->
        <div class="floatPopuop">
            <div class="customerService" @click="promotionCashback">
                <div>
                    <img src="@/assets/htui/tgfx.png" alt="">
                </div>
                <div>推广返现</div>
            </div>
            <div class="customerService" @click="tapServe">
                <div>
                    <img src="@/assets/htui/service.png" alt="">
                </div>
                <div>在线客服</div>
            </div>
        </div>

        <div class="bottomPopup" @click="showChatPopup">
            <div class="talkContent" v-if="!takeIndex">
                <img src="@/assets/htui/messagechat.png" alt="">
                <p>您好，我是您的法律小助手！</p>
            </div>

            <div class="talkContent2" v-if="takeIndex">
                <img src="@/assets/htui/messagechat.png" alt="">
                <p>有法律问题可以随时问我！</p>
            </div>
            <img src="@/assets/htui/aichat.png" alt="">
        </div>


        <chatContent v-if="chatPopup"></chatContent>
    </div>
</template>

<script>
import loginPopup from '@/views/components/login.vue'
import { getToken, removeToken } from '@/utils/auth';
import cashbackTank from '@/views/components/userContent/cashbackPopup.vue'
import vipRecharge from '@/views/components/userContent/viprecharge.vue'
import chatContent from '@/components/chat/index.vue'
import Cookies from "js-cookie";
export default {
    data() {
        return {
            routerList: [
                {
                    routerName: '首页',
                    linkUrl: '/index',
                },
                {
                    routerName: '合同模板',
                    linkUrl: '/contractTemplate',
                },
                {
                    routerName: '私有化部署',
                    linkUrl: '/deployment',
                },
                // {
                //     routerName: '商业合作',
                //     linkUrl: '/cooperation',
                // },
                {
                    routerName: '系统集成',
                    linkUrl: '/systemIntegration',
                },

                {
                    routerName: '关于我们',
                    linkUrl: '/aboutUs',
                },
                {
                    routerName: '智能比对',
                    linkUrl: '/compareText'
                },
                {
                    routerName: '合同审核',
                    linkUrl: '/examine',
                },

            ],
            isLogin: false,
            showLoginPopup: false,
            activeIndex: '',
            recordNumber: '',
            showCashbackPopup: false,
            showVipRechargePopup: false,
            chatPopup: false,
            takeIndex: false,
            isVip: false,
            isupline: false
        };
    },
    components: { loginPopup, cashbackTank, chatContent, vipRecharge },
    mounted() {
        if (window.location.hostname == 'htagi.cn') {
            this.recordNumber = '冀ICP备2021001057号-6'
        } else {
            this.recordNumber = '冀ICP备2021001057号-3'
        }
        this.isLogin = getToken() ? true : false
        this.activeIndex = this.$route.path;
        console.log(this.activeIndex, "activeIndex")

        //下拉后修改背景色和样式
        window.addEventListener('scroll', () => {
            if (this.activeIndex == '/index') {
                let top_section = document.querySelector('.top_section');
                if (window.scrollY > 0) {
                    top_section.classList.add("whiteContent");
                } else {
                    top_section.classList.remove("whiteContent");
                }
            }
        })

        setInterval(() => {
            this.takeIndex = !this.takeIndex
        }, 6.5 * 1000);

    },
    updated() {
        const that = this;
        const mypath = that.$route.path;
        if (mypath && mypath.length > 0) {
            that.activeIndex = mypath;
        }
    },

    methods: {
        //跳转路由
        goRouter(item) {
            this.$router.push(item.linkUrl);
            this.activeIndex = item.linkUrl;
        },
        //跳转个人中心
        tapUser() {
            this.$router.push('/personalCenter');
        },
        //关于我们
        aboutUsTap() {
            this.$router.push('/aboutUs');
        },
        //退出登录
        tapLoginOut() {
            this.$confirm('是否确认退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                removeToken();
                Cookies.remove('username');
                this.isLogin = false;
                localStorage.removeItem('AccessToken_expriesTime')
                location.href = '/index';
                this.$bus.$emit('loginButton', false);
            }).catch(() => {
            });
        },
        //打开法律小助手
        showChatPopup() {
            if (this.isLogin) {
                this.chatPopup = true;
            } else {
                this.showLoginPopup = true
            }
        },

        //打开vip充值弹框
        showVipRecharge() {
            if (this.isLogin) {
                this.showVipRechargePopup = true;
            } else {
                this.showVipRechargePopup = true
            }
        },
        //点击客服
        tapServe() {
            this.centerWindow('https://work.weixin.qq.com/kfid/kfc5361bd75603839f6', 1000, 600);
        },
        //新窗口居中弹出
        centerWindow(url, width, height) {
            const screenLeft = (window.screen.width - width) / 2;
            const screenTop = (window.screen.height - height) / 2;
            window.open(url, '_blank', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${screenTop}, left=${screenLeft}`);
        },
        //打开推广返现弹框
        promotionCashback() {
            this.showCashbackPopup = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.top_section {
    position: fixed;
    z-index: 21;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    color: white;

    .visible_area {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logoAndtitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - 180px);

            .logo {
                cursor: default;
                width: 230px;
                height: 90px;
                display: flex;
                align-items: center;
                font-size: 28px;
                justify-content: space-between;

                img {
                    width: 45px;
                    height: 45px;
                    margin-left: 10px;
                    filter: brightness(0) invert(1);
                }
            }

            .routers {
                display: flex;
                height: 20px;
                align-items: center;
                margin-left: 20px;
                box-sizing: border-box;
                font-size: 16px;

                >div {
                    text-align: center;
                    white-space: nowrap;
                    width: 110px;

                    .activeColor {
                        color: #2d8cf0 !important;
                    }

                    span {
                        cursor: pointer;
                        font-weight: bold;
                    }

                    span :hover {
                        color: #2d8cf0;
                    }

                    .nomalColor :hover {
                        color: #2d8cf0;
                    }
                }
            }
        }

        .wxLogin {
            display: flex;
            width: 140px;
            height: 40px;
            border-radius: 25px;
            background-color: #2f499d;
            font-size: 14px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            >img {
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
        }

        .wxloginuser {
            display: flex;
            align-items: center;


            .right_user {
                width: 40px;
                height: 40px;
                margin-left: 12px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .vipContent {
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                padding: 8px 12px 8px 10px;
                font-size: 13px;
                box-sizing: border-box;
                background: linear-gradient(270deg, #ffcaa8, #ffddc7);
                border-radius: 7px;
                cursor: pointer;
                // overflow: hidden;
                color: #653619;
                font-weight: 500;
                white-space: nowrap;


                .floatAni {
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    overflow: hidden;

                }

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 2px;
                }
            }

            @keyframes light-line {
                0% {
                    -webkit-transform: translateX(-100%) rotate(-45deg);
                    transform: translateX(-100%) rotate(-45deg)
                }

                to {
                    -webkit-transform: translateX(100%) rotate(-45deg);
                    transform: translateX(100%) rotate(-45deg)
                }
            }


            .floatAni:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .3) 40.1%, hsla(0, 0%, 100%, .3) 55.21%, hsla(0, 0%, 100%, 0));
                -webkit-animation: light-line 2s linear infinite;
                animation: light-line 2s linear infinite;
                animation-duration: 2s;
                animation-timing-function: linear;
                animation-delay: 0s;
                animation-iteration-count: infinite;
                animation-direction: normal;
                animation-fill-mode: none;
                animation-play-state: running;
                animation-name: light-line;
            }




            .floatBox {
                width: 300px;
                // height: 200px;
                position: absolute;
                right: 0;
                top: 30px;
                visibility: hidden;
                opacity: 0;
                cursor: auto !important;
                padding: 12px 0;

                .floatinner {
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    background-color: white;
                    text-align: left;
                    transition: visibility 0s, opacity 0.5s linear;
                    box-shadow: 0 10px 40px 0 rgb(0 0 0 / 36%);
                    padding: 15px 20px;
                    box-sizing: border-box;
                    border-radius: 8px;
                    flex-wrap: wrap;

                    .vipTitle {
                        font-size: 16px;
                        font-weight: bold;
                        color: #472815;
                        color: #ffc8a3;
                        display: flex;
                        align-items: center;
                        letter-spacing: 2px;
                        margin-bottom: 15px;

                        img {
                            height: 18px;
                            width: 18px;
                            margin-right: 4px;
                        }
                    }

                    .section1 {
                        color: #000000;
                        font-size: 14px;
                        margin: 4px 0;
                    }

                    .description1 {
                        font-size: 12px;
                        color: gray;
                        width: 100%;
                        white-space: normal;
                        margin-bottom: 15px;
                    }

                    .btnStart {
                        position: relative;
                        width: 248px;
                        height: 36px;
                        margin: 24px auto 0;
                        color: #472815;
                        color: var(--text-on-gold, #472815);
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 36px;
                        text-align: center;
                        background: #ffc8a3;
                        background: var(--background-gold, #ffc8a3);
                        border-radius: 18px;
                        cursor: pointer;
                    }
                }

                .box-top {
                    height: 40px;
                    display: flex;

                    >div:nth-child(1) {
                        img {
                            width: 35px;
                            height: 35px
                        }
                    }

                    >div:nth-child(2) {
                        width: calc(100% - 60px);
                        margin-left: 10px;
                        font-size: 14px;



                        >div:nth-child(2) {
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            margin-top: 4px;
                            font-size: 12px;
                            color: gray;
                        }
                    }
                }

                .vipdata {
                    height: 110px;
                    width: 100%;
                    background-image: url(../../assets/htui/vipZs.jpg);
                    background-size: 100% 100%;
                    padding: 10px;
                    box-sizing: border-box;

                    >div:nth-child(1) {
                        font-size: 16px;
                        color: #472815;
                        font-weight: bold;
                        margin-top: 6px;
                    }

                    >div:nth-child(2) {
                        font-size: 12px;
                        color: #472815;
                        margin-top: 4px;
                    }

                    >div:nth-child(3) {
                        max-width: 60px;
                        background-color: #000000;
                        color: white;
                        font-size: 12px;
                        padding: 4px 4px;
                        border-radius: 6px;
                        color: #ffcaa8;
                        margin-top: 15px;
                        cursor: pointer;
                        text-align: center;
                    }
                }

                .funList {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    >div {
                        width: 48%;
                        height: 60px;
                        background-color: #fafafc;
                        border-radius: 4px;
                        margin-bottom: 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: left;
                        padding: 0px 15px;
                        box-sizing: border-box;


                        >div:nth-child(1) {
                            font-size: 14px;
                            color: #000000;
                            text-align: left;
                            width: 100%;
                        }

                        >div:nth-child(2) {
                            font-size: 12px;
                            color: gray;
                            text-align: left;
                            width: 100%;
                        }
                    }
                }

                .line-l {
                    width: 64px;
                    height: 1px;
                    background: linear-gradient(90deg, #fafafa, #e3e4e5);
                }

                .linetext {
                    margin: 0 8px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #303133;
                }

                .line-r {
                    width: 64px;
                    height: 1px;
                    background: linear-gradient(90deg, #e3e4e5, #fafafa);
                }

                .fgx {
                    height: 1px;
                    background-color: rgb(230, 230, 230);
                    width: 100%;
                    margin: 20px 0;
                }

                .syyl {
                    height: 60px;
                    width: 100%;
                    background-color: #fafafc;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                }

                .changeLicense {
                    text-align: right;
                    margin-top: 15px;
                    font-size: 14px;
                    color: #2d8cf0;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: right;
                }
            }


            .vipContent:hover .floatBox {
                visibility: visible;
                z-index: 999999;
                animation: visibleline 0.5s linear forwards;
            }


            @keyframes visibleline {
                0% {
                    opacity: 0;
                }

                to {
                    opacity: 1;
                }
            }

            font-size: 14px;
            cursor: pointer;
            white-space: nowrap;

            span:nth-child(2) {
                margin: 0 2px;
            }
        }
    }
}

.whiteContent {
    background: #fff;
    box-shadow: 0 1px 2px 0 #efefef;

    .routers {
        >div {
            color: #000000;
        }
    }

    .logo {
        color: #000000;

        img {
            filter: none !important;
        }
    }

    .wxloginuser {
        color: #000000 !important;
    }

    animation: 0.6s changeColor ease forwards;
}

@keyframes changeColor {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: white;
    }
}

.emptyContent {
    height: 90px;
}

.footerContent {
    min-width: 1400px;
    // max-height: 450px;
    width: 100%;
    padding: 20px 0 60px 0;
    background-color: white;
    padding-top: 5px;
    position: relative;


    .beianhao {
        width: 1200px;
        text-align: center;
        text-align: center;
        margin: 0 auto;
        color: black;
        font-size: 12px;
        margin-top: 30px;

        a {
            color: #2d8cf0;
            margin-left: 20px;
        }
    }

    .wztitle {
        // background-color: white;
        color: white;
        padding: 10px;
        box-sizing: border-box;
        width: 200px;
        font-size: 24px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
    }
}

.menuList {
    display: flex;
    width: 1000px;
    margin: 0 auto;
    justify-content: center;

    span {
        font-size: 16px;
        color: black;
        margin: 10px 0;
        display: block;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: left;
    }

    ul {
        font-size: 13px;
        text-align: left;
        margin: 0;
        padding: 0;
        display: flex;
        min-width: 280px;
        flex-wrap: wrap;
    }

    li {
        list-style-type: none;
        text-align: left;
        font-size: 14px;
        padding: 0px 10px 15px 0;
        color: black;
        cursor: pointer;
        margin-right: 20px;
        min-width: 80px;
        white-space: nowrap;
    }

    li:hover {
        color: #2d8cf0 !important;


    }

    a:hover {
        color: #2d8cf0 !important;
    }

    .compMessage {
        text-align: left;
        margin-left: 100px;

        span {
            font-size: 16px;
            color: black;
            margin: 10px 0;
            display: block;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        div {
            font-size: 14px;
            color: black;
            margin-bottom: 15px;
        }
    }
}


.floatPopuop {
    position: fixed;
    right: 20px;
    bottom: 90px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px 0 rgb(171 171 171);
    background-color: white;
    z-index: 999999 !important;
    border-radius: 15px;


    >div:nth-child(1) {
        border-bottom: 1px solid #efefef;
    }

    .customerService {
        width: 80px;
        height: 85px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        min-width: 80px;
        cursor: pointer;

        >div:nth-child(1) {
            width: 30px;
            height: 30px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        >div:nth-child(2) {
            font-size: 14px;
        }
    }
}

.bottomPopup {
    // height: 200px;
    // background-color: rgb(102, 150, 255);
    position: fixed;
    right: 20px;
    bottom: 280px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 14px;
    cursor: pointer;

    .talkContent {
        height: 25px;
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        position: absolute;
        top: -10px;
        right: 30px;
        color: black;
        opacity: 0;
        font-size: 12px;
        animation: slide 6s ease;
        box-shadow: 1px 1px 6px 1px #0000001f;
        border-radius: 20px;

        img {
            width: 18px;
            height: 16px;
            margin-right: 4px;
        }
    }


    @keyframes slide {
        0% {
            top: -10px;
            opacity: 0;
        }

        15% {
            top: -30px;
            opacity: 1;
        }

        90% {
            top: -30px;
            opacity: 1;
        }

        100% {
            top: -50px;
            opacity: 0;
        }
    }


    .talkContent2 {
        height: 25px;
        width: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        position: absolute;
        top: -10px;
        right: 30px;
        color: black;
        box-shadow: 1px 1px 8px 1px #0000001f;
        opacity: 0;
        font-size: 12px;
        animation: slide2 6s ease forwards;
        border-radius: 20px;

        img {
            width: 18px;
            height: 16px;
            margin-right: 4px;
        }
    }


    @keyframes slide2 {
        0% {
            top: -10px;
            opacity: 0;
        }

        15% {
            top: -30px;
            opacity: 1;
        }

        90% {
            top: -30px;
            opacity: 1;
        }

        100% {
            top: -50px;
            opacity: 0;
        }
    }


    img {
        width: 100px;
        height: 100px;
    }

}
</style>
