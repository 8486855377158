//其他基本数据接口
import request from '@/utils/chatRequest'

//获取gpt历史记录
export function getGptHistory(query) {
    return request({
        url: '/gptChat/v1/messages',
        method: 'get',
        params: query,
    })
}

//获取gpt历史会话
export function getConversationsHistory(query) {
    return request({
        url: '/gptChat/v1/conversations',
        method: 'get',
        params: query,
    })
}

//点赞
export function feedbacks(messageId) {
    return request({
        url: `/gptChat/v1/messages/${messageId}/feedbacks`,
        method: 'get',
        params: query,
    })
}
