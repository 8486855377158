import Vue from 'vue'
import VueRouter from 'vue-router'
import mainLayout from '@/views/htui/layout'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  /**
   * 合同审核路由
   */
  {
    path: '',
    component: mainLayout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import('@/views/htui/pages/index'),
        name: 'index'
      },
      {
        path: '/contractTemplate',
        component: () => import('@/views/htui/pages/contractTemplate'),
        name: 'contractTemplate'
      },
      {
        path: '/limitedTimeActivities',
        component: () => import('@/views/htui/pages/limitedTimeActivities'),
        name: 'limitedTimeActivities'
      },
      // {
      //   path: '/cooperation',
      //   component: () => import('@/views/htui/pages/cooperation'),
      //   name: 'cooperation'
      // },
      {
        path: '/systemIntegration',
        component: () => import('@/views/htui/pages/systemIntegration'),
        name: 'systemIntegration'
      },
      {
        path: '/examine',
        component: () => import('@/views/htui/pages/examine'),
        name: 'examine'
      },
      {
        path: '/recharge',
        component: () => import('@/views/htui/pages/recharge'),
        name: 'recharge'
      },
      {
        path: '/personalCenter',
        component: () => import('@/views/htui/pages/personalCenter'),
        name: 'personalCenter'
      },
      {
        path: '/deployment',
        component: () => import('@/views/htui/pages/deployment'),
        name: 'deployment'
      },
      {
        path: '/wordPreview',
        component: () => import('@/views/htui/pages/wordPreview'),
        name: 'wordPreview'
      },
      {
        path: '/payment',
        component: () => import('@/views/htui/pages/payment'),
        name: 'payment'
      },
      {
        path: '/agreement',
        component: () => import('@/views/htui/pages/agreement'),
        name: 'agreement'
      },
      {
        path: '/orderPage',
        component: () => import('@/views/htui/pages/orderPage'),
        name: 'orderPage'
      },
      {
        path: '/contractPreview',
        component: () => import('@/views/htui/pages/contractPreview'),
        name: 'contractPreview'
      },
      {
        path: '/compareText',
        component: () => import('@/views/text/compare.vue'),
        name: 'compareText',
        hidden: true
      },
      {
        path: '/aboutUs',
        component: () => import('@/views/htui/pages/aboutUs'),
        name: 'aboutUs',
        hidden: true
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
