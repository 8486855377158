<template>
  <div id="app" class="app_wrapper">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      referrer: ''
    }
  },
  components: {
  },
  created() {
    this.getReferrer()
  },
  methods: {
    //获取访问来源
    getReferrer() {
      this.referrer = document.referrer;
      if (this.referrer) {
        localStorage.setItem('referrer', this.referrer)
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 18px;
}

i {
  font-size: 18px !important;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
