import request from '@/utils/request'
// 登录
export function login(data) {
    return request({
        url: '/prod-api/api/token',
        method: 'post',
        data: data
    })
}

//找回密码
export function forgetPassword(data) {
    return request({
        url: '/prod-api/api/forgetPassword',
        method: 'post',
        data: data
    })
}

//注册账号
export function register(data) {
    return request({
        url: '/prod-api/api/register',
        method: 'post',
        data: data
    })
}

//短信验证码 （注册 1  找回 2）
export function sendsms(data) {
    return request({
        url: '/prod-api/sendsms',
        method: 'post',
        data: data
    })
}


//修改密码
export function changePassword(data) {
    return request({
        url: '/prod-api/api/changePassword',
        method: 'post',
        data: data
    })
}