import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 分页组件
import Pagination from "@/components/pagination";
import "./permission"; // permission control
import './utils/rem'
import "@/assets/style/index.scss"; // global css

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.component("Pagination", Pagination);

Vue.prototype.$bus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
