//其他基本数据接口
import request from '@/utils/request'

//个人信息
export function getUserInfo() {
    return request({
        url: '/prod-api/api/getUserInfo',
        method: 'get'
    })
}

//修改个人信息
export function updateUserMessage(data) {
    return request({
        url: '/prod-api/api/updateUserInfo',
        method: 'post',
        data: data
    })
}

//上传word 审核
export function checkFile(data) {
    return request({
        url: '/prod-api/sse/checkFile',
        method: 'post',
        data: data,
        type: 'multipart/form-data'
    })
}

//查询核查进度
export function verifyProgress(data) {
    return request({
        url: '/prod-api/sse/checkFileStatus',
        method: 'post',
        data: data,
        type: 'application/x-www-form-urlencoded;charset=utf-8'
    })
}

//核查风险统计数接口
export function getCountingNumber(data) {
    return request({
        url: '/prod-api/sse/getFileRisk',
        method: 'POST',
        data: data,
        type: 'application/x-www-form-urlencoded;charset=utf-8'
    })
}

//获取word路径
export function scanFilePdf(userToken) {
    return request({
        url: '/prod-api/sse/scanFilePdf/' + userToken,
        method: 'get'
    })
}



//服务次数
export function serviceNum() {
    return request({
        url: '/prod-api/system/config/configKeyNotoken/serviceNum',
        method: 'get'
    })
}


//插入审核记录
export function insertAudit(data) {
    return request({
        url: '/prod-api/base/documentReviewRecords/add',
        method: 'post',
        data: data
    })
}

//查询审核记录
export function getDocumentReviewRecords(query) {
    return request({
        url: '/prod-api/base/documentReviewRecords/list',
        method: 'get',
        params: query
    })
}

//删除审核记录
export function deleteDocumentReviewRecords(recordId) {
    return request({
        url: '/prod-api/base/documentReviewRecords/deleteById/' + recordId,
        method: 'post'
    })
}


//查询审核记录 （未支付）
export function getDocumentNoPayReviewRecords(query) {
    return request({
        url: '/prod-api/base/documentReviewRecords/unpaidList',
        method: 'get',
        params: query
    })
}


//查询评价信息列表
export function documentEvaluate(query) {
    return request({
        url: '/prod-api/base/documentEvaluate/list',
        method: 'get',
        params: query
    })
}

//新增评价
export function addEvaluate(data) {
    return request({
        url: '/prod-api/base/documentEvaluate/add',
        method: 'post',
        data: data
    })
}

//获取合同模版列表
export function getTemplateList(data) {
    return request({
        url: '/prod-api/base/contractTemplate/list',
        method: 'get',
        params: data
    })
}

//获取模版详情

export function getTemplateDetail(query) {
    return request({
        url: '/prod-api/base/contractTemplate/getById',
        method: 'get',
        params: query
    })
}


//宣传文案
export function getAdMsg(data) {
    return request({
        url: '/prod-api/sse/getAdMsg',
        method: 'post',
        data: data,
        type: 'application/x-www-form-urlencoded;charset=utf-8'
    })
}

//模版分类列表
export function getAllContractType(query) {
    return request({
        url: '/prod-api/base/contractTemplate/allContractType',
        method: 'get',
        params: query
    })
}

//推广返现比例
export function getConfigKey(configKey) {
    return request({
        url: '/prod-api/system/config/configKey/' + configKey,
        method: 'get'
    })
}

//模版、价格
export function getServeGoods(query) {
    return request({
        url: '/prod-api/base/serveGoods/list',
        method: 'get',
        params: query
    })
}

//查询当前合同价格
export function checkFilePrice(data) {
    return request({
        url: '/prod-api/sse/checkFilePrice',
        method: 'post',
        data: data,
        type: 'multipart/form-data'
    })
}