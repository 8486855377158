<template>
    <div class="common-back">
        <div class="common-layout">
            <div class="box-card">
                <div style="height:50px;line-height: 50px;font-size: 16px;font-weight: bold;position: relative">
                    <span>法律小助手</span>

                    <div class='closeImg' @click="closePopup">
                        <img src="@/assets/htui/close.png" alt="">
                    </div>
                </div>
                <!-- 消息容器 -->
                <div class="message-container" ref="messageContainer">
                    <div v-for="(message, index) in messages" :key="index" class="message-item"
                        :class="{ 'user-message': message.isUser, 'bot-message': !message.isUser }">
                        <div :class="message.isUser ? 'textContent_Right' : 'textContent_Left'">
                            <div v-if="!message.isUser" class="avaterContent">
                                <img src="@/assets/logo.png" alt="">
                            </div>
                            <div class="anserContent">
                                <div v-if="message.isTyping" class="text-message"
                                    v-html="parseMarkdown(message.content)">
                                </div>
                                <div v-else-if="message.content" class="text-message"
                                    v-html="parseMarkdown(message.content)">
                                </div>

                                <div v-if="message.content && message.isok && !message.isUser" class="withContent">
                                    <!-- <div class="copytext" @click="likeit(message)">
                                        <img src="@/assets/htui/like.png" alt="">
                                    </div> -->
                                    <div class="copytext" @click="copyText(message.content, $event)">

                                    </div>


                                </div>
                                <div v-else-if="message.error" class="error-message">{{ message.content }}</div>
                            </div>

                            <div v-if="message.isUser" class="avaterContent">
                                <img src="@/assets/htui/user.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chat-input">
                    <el-input @keyup.enter.native="sendMessage" :disabled="robotIsReplying" v-model="userInput"
                        type="textarea" autosize class="fixed-size-textarea" placeholder="请输入想咨询的问题,Enter键发送...">
                    </el-input>
                    <el-button :disabled="robotIsReplying" @click="sendMessage" type="primary"
                        style="position: absolute;right:25px;bottom: 20px;z-index: 9;">发送</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MarkdownIt from 'markdown-it';
const md = new MarkdownIt();
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { getConversationsHistory, getGptHistory, feedbacks } from '@/api/gptChat'
import handleCopy from '@/utils/clipboard'
export default {
    data() {
        return {
            userInput: '',  //用户输入
            messages: [{ content: '你好，我是您的法律助手，您有什么问题可以随时咨询?', isUser: false, isok: false }],
            password: '',
            showPasswordCard: false,
            robotIsReplying: false, // 用于标识机器人是否正在回复
            textToCopy: '',
            // 在 data 中添加用于存储用户输入历史的变量
            chatHistory: [],  // 用于存储对话历史
            SETDATA: false,
            isHovered: false,
            conversationId: ''
        };
    },
    created() {
        // this.getHistory()
    },
    methods: {
        parseMarkdown(text) {
            return md.render(text);
        },
        copyText(text, e) {
            let value = text  //这里设置你要复制的文本信息，可以是text，也可以从e中获取，也可以自定义
            handleCopy(value, e, () => {
                this.$message.success('复制成功')
            }, () => {
                this.$message.warning('复制失败')
            })
        },
        likeit(message) {
            if (message.messageId) {
                console.log(message, "点赞")
                feedbacks(message.messageId).then((res) => {
                    console.log(res, "点赞成功")
                })
            }
        },
        //关闭弹框
        closePopup() {
            this.$parent.chatPopup = false;
        },
        async sendMessage() {
            this.robotIsReplying = true;
            if (this.userInput === '') { this.robotIsReplying = false; return; }
            //特定字符拦截
            if (this.userInput.trim() === '') return;
            this.messages.push({ content: this.userInput, isUser: true });
            try {
                const fullUserMessage = this.userInput;
                this.chatRequest(fullUserMessage);
                this.messages.push({ content: "正在生成回答...", isTyping: true }); // 显示正在输入状态
                this.$nextTick(() => {
                    const messageContainer = this.$refs.messageContainer;
                    messageContainer.scrollTop = messageContainer.scrollHeight;
                })
            } catch (error) {
                this.userInput = ''; // 清空用户输入
                this.robotIsReplying = false;
                this.messages = [...this.messages.filter(msg => !msg.isTyping)]; // 清除正在输入状态
                this.messages.push({ content: '我们正在优化中...', isUser: false, error: true }); // 添加错误消息
            }
            this.userInput = ''; // 清空用户输入
        },

        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        matchSentences(text) {
            const punctuation = '。，！？；：、';
            let lastIndex = -1;
            let secondLastIndex = -1;

            for (let i = text.length - 1; i >= 0; i--) {
                if (punctuation.includes(text[i])) {
                    if (lastIndex === -1) {
                        lastIndex = i;
                    } else {
                        secondLastIndex = i;
                        break;
                    }
                }
            }

            if (secondLastIndex === -1) {
                return text.slice(0, lastIndex + 1);
            } else {
                return text.slice(secondLastIndex + 1, lastIndex + 1);
            }
        },
        chatRequest(msg) {
            return new Promise((reslove, reject) => {
                let textDATA = ''
                let ansers = ''
                let hisbox = [];
                let isAnswer = true;
                let addText = false;
                let data = {
                    "inputs": {},
                    "query": msg,
                    "response_mode": "streaming",
                    "conversation_id": this.conversationId,
                    "user": "abc-1"

                }
                let setData = async () => {
                    addText = true;
                    if (ansers.length > 0) {
                        await this.delay(30); // 等待一段时间再添加下一个字
                        this.messages[this.messages.length - 1].content += ansers.charAt(0);
                        ansers = ansers.slice(1);
                        await this.$nextTick(() => {
                            const messageContainer = this.$refs.messageContainer;
                            // 滚动到消息容器的底部
                            messageContainer.scrollTop = messageContainer.scrollHeight;
                        })
                        setData()
                    } else {
                        if (isAnswer) {
                            setTimeout(() => {
                                setData()
                            }, 200);
                        } else {
                            this.messages[this.messages.length - 1].isok = true
                            return
                        }
                    }
                }
                let that = this;
                fetchEventSource('/gptChat/v1/chat-messages', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'text/event-stream',
                        'Authorization': 'Bearer app-oU8NAOQ8UuD0bv3vA571BI9e'
                    },
                    body: JSON.stringify(data),
                    onopen() {
                    },
                    onmessage(ev) {
                        if (ev.data && JSON.parse(ev.data).answer && JSON.parse(ev.data).event == 'message') {
                            let messageId = JSON.parse(ev.data).message_id ? JSON.parse(ev.data).message_id : ''
                            that.conversationId = JSON.parse(ev.data).conversation_id ? JSON.parse(ev.data).conversation_id : '';
                            if (textDATA.length == 0) {
                                that.messages = [...that.messages.slice(0, -1), { content: '', isUser: false, isok: false, messageId: messageId }];
                            }
                            textDATA += JSON.parse(ev.data).answer;
                            let splitText = that.matchSentences(textDATA);
                            splitText = splitText.replace('阿里云', '网秦软件公司');
                            splitText = splitText.replace('通义千问', '小秦');
                            splitText = splitText.replace('Qwen', 'WQ-wen');
                            if (hisbox.length == 0) {
                                hisbox.push(splitText)
                                ansers += splitText;
                            } else {
                                if (hisbox[hisbox.length - 1] != splitText) {
                                    hisbox.push(splitText)
                                    ansers += splitText;
                                }
                            }
                            if (ansers.length > 0) {
                                addText ? '' : setData()
                            }
                        }
                    },
                    onclose() {
                        isAnswer = false;
                        addText = false;
                        that.robotIsReplying = false;
                    },
                    onerror(err) {
                        that.messages.push({ content: 'ChatGPT 发生错误', isUser: false, error: true }); // 添加错误消息
                    }
                })
            })
        },
    },
}

</script>
<style lang="scss" scoped>
.common-back {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999 !important;
}

@keyframes scaleShow {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.closeImg {
    position: absolute;
    right: 20px;
    top: 5px;
    cursor: pointer;

    img {
        width: 35px;
        height: 35px;
    }
}

.common-layout {
    left: 300px;
    top: 60px;
    bottom: 80px;
    right: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 9999999999999 !important;
    min-width: 800px;
    background-color: white;
    border-radius: 10px;
    animation: scaleShow 0.6s ease forwards;
}


::v-deep .fixed-size-textarea .el-textarea__inner {
    width: 100% !important;
    height: 100px !important;
    resize: none;
    padding-right: 120px;
}


.user-message,
.bot-message {
    margin-bottom: 30px;
}

.user-message {
    text-align: right;
}

.bot-message {
    text-align: left;
}

.chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 100px;
    position: relative;
    box-sizing: border-box;
    margin-top: 15px
}

.chat-input el-input {
    flex: 1;
    margin-right: 10px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box-card {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.typing-indicator {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 0;
}

.typing-indicator:before {
    display: block;
    content: "|";
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    right: 0;
    animation: typing 0.5s steps(30, end) infinite;
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: auto;
    }
}

.error-message {
    color: red;
    font-style: italic;
}

@keyframes typing {
    0% {
        height: 0;
    }

    50% {
        height: 1em;
    }

    100% {
        height: 0;
    }
}


.chat-container {
    width: 100vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100vh;

}

.message-container {
    background-color: rgb(241, 248, 255);
    overflow-y: auto;
    margin-left: auto;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 180px);
    padding: 20px 50px;
    box-sizing: border-box;
}

.user-message {
    text-align: right;

}

.bot-message {
    text-align: left;
}

.input-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.textContent_Right {
    display: flex;
    width: 100%;
    justify-content: right;

    .anserContent {
        background-color: white;
        padding: 2px 10px;
        margin-right: 15px;
        border-radius: 10px;
        font-size: 16px !important;
    }
}

.textContent_Left {
    display: flex;
    width: 100%;
    justify-content: left;

    .anserContent {
        background-color: white;
        padding: 2px 10px;
        margin-left: 15px;
        border-radius: 12px;
        width: calc(100% - 70px);
        font-size: 16px !important;
    }
}

.withContent {
    height: 30px;
    width: 98%;
    display: flex;
    justify-content: right;

    .copytext {
        width: 26px;
        height: 26px;
        background-image: url('../../assets/htui/copyContent.png');
        background-size: 100% 100%;
        cursor: pointer;
    }

    .copytext:hover {
        background-image: url('../../assets/htui/copy2.png') !important;
        background-size: 100% 100%;
        cursor: pointer;
    }
}

.avaterContent {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
}
</style>