import router from './router';
import { getToken } from '@/utils/auth';
const backList = ['/recharge', '/personalCenter', '/orderPage', '/payment', '/contractPreview'];
router.beforeEach((to, from, next) => {
  if (to.query && to.query.inviteCode) {
    localStorage.setItem('inviteCode', to.query.inviteCode);
  }
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  if (isMobile) {
    window.location.href = '/mobile/index.html';
  } else {
    if (getToken()) {
      next();
    } else {
      // 没有token
      if (backList.indexOf(to.path) !== -1) {
        next(`/index`);
      } else {
        next();
      }
    }
  }
});
router.afterEach(() => {
  window.scrollTo(0, 0);
})
